<template>
  <div>
    <div class="nav-wrapper">
      <RouterLink to="/">
        <img src="@/assets/logo.png" alt="m2VET">
      </RouterLink>
      <div class="nav-wrapper__inner">
        <RouterLink to="/">Strona główna</RouterLink>
        <RouterLink to="/blog">Blog</RouterLink>
        <RouterLink to="/announcements">Ogłoszenia</RouterLink>
        <a href="http://warsztaty.m2vet.pl" target="_blank">Warsztaty on-line</a>
        <RouterLink to="/login" class="account-link">{{ currentUser ? 'Konto' : 'Zaloguj się' }}<span>{{ currentUser ? currentUserData.data.name : null }}</span></RouterLink>
        <RouterLink to="/register" v-if="!currentUser">Zarejestruj się</RouterLink>
        <ButtonVue style="padding: 15px; border-radius: 15px;" v-if="isBiz" :text="isBiz=='true' ? 'dla usługobiorcy' : 'dla biznesu'" bgColor="#f27a07" color="#000" @click="() => {
          $emit('toggleBlur')
          this.$store.commit('setIsBusiness', isBiz)
          }"/>
      </div>
    </div>
    <div class="profile-nav-container" v-if="currentLocation().split('/')[1] == 'profile'">
      <RouterLink to="/profile/user-data">Twoje dane</RouterLink>
      <RouterLink to="/profile/visits">Wizyty</RouterLink>
      <RouterLink to="/profile/recommendations">Zalecenia</RouterLink>
      <RouterLink to="/profile/messages">Wiadomości</RouterLink>
      <RouterLink to="/profile/fav-services">Ulubione usługi</RouterLink>
      <RouterLink to="/profile/announcements">Moje ogłoszenia</RouterLink>
      <RouterLink to="/profile/report-error">Zgłoś błąd <img src="@/assets/icons/question-mark.svg" alt=""></RouterLink>
      <button @click="logOut">wyloguj <img src="@/assets/icons/power.svg" alt=""></button>
    </div>
    <div class="profile-nav-container" v-if="currentLocation().split('/')[1] == 'admin-profile'">
      <RouterLink to="/admin-profile/categories">Kategorie</RouterLink>
      <RouterLink to="/admin-profile/contact-info">Dane kontaktowe</RouterLink>
      <RouterLink to="/admin-profile/blog">Blog</RouterLink>
      <RouterLink to="/admin-profile/footer">Stopka</RouterLink>
      <RouterLink to="/admin-profile/partners">Partnerzy</RouterLink>
      <RouterLink to="/admin-profile/mailbox">Wiadomości</RouterLink>
      <RouterLink to="/admin-profile/regulations">Regulaminy</RouterLink>
      <RouterLink to="/admin-profile/service-verify">Weryfikowanie usług</RouterLink>
      <RouterLink to="/admin-profile/users">Użytkownicy</RouterLink>
      <RouterLink to="/admin-profile/users/download">Użytkownicy CSV</RouterLink>
      <RouterLink to="/admin-profile/price-list">Ceny</RouterLink>
      <RouterLink to="/admin-profile/subscribers">Subskrybenci</RouterLink>
      <RouterLink to="/admin-profile/recommenders">Polecający</RouterLink>
      <RouterLink to="/admin-profile/page-reviews">Oceny serwisu</RouterLink>
      <RouterLink to="/admin-profile/announcements-verify">Weryfikowanie ogłoszeń</RouterLink>
      <RouterLink to="/admin-profile/reports">Zgłoszenia</RouterLink>
      <RouterLink to="/admin-profile/newsletter">Newsletter</RouterLink>
      <RouterLink to="/admin-profile/titles">Tytuły usług</RouterLink>
      <RouterLink to="/admin-profile/change-password">Zmień hasło</RouterLink>
      <button @click="logOut">wyloguj <img src="@/assets/icons/power.svg" alt=""></button>
    </div>
    <div class="profile-nav-container" v-if="currentLocation().includes('/dla-biznesu/profile')">
      <RouterLink to="/dla-biznesu/profile/user-data">Twoje dane</RouterLink>
      <RouterLink to="/dla-biznesu/profile/announcements">Ogłoszenia</RouterLink>
      <RouterLink to="/dla-biznesu/profile/subscriptions">Subskrypcje</RouterLink>
      <RouterLink v-if="$store.state.isSubscribed" to="/dla-biznesu/profile/services-provided">Świadczone usługi</RouterLink>
      <RouterLink v-if="$store.state.isSubscribed" to="/dla-biznesu/profile/messages">Wiadomości</RouterLink>
      <RouterLink to="/dla-biznesu/profile/employees">Pracownicy</RouterLink>
      <RouterLink v-if="$store.state.isSubscribed" to="/dla-biznesu/profile/calendar">Kalendarz</RouterLink>
      <RouterLink v-if="$store.state.isSubscribed" to="/dla-biznesu/profile/recommendations">Zalecenia</RouterLink>
      <RouterLink v-if="$store.state.isSubscribed" to="/dla-biznesu/profile/useful-tips">Porady <img src="@/assets/icons/bulb.svg" alt=""></RouterLink>
      <RouterLink to="/dla-biznesu/profile/report-error">Zgłoś błąd <img src="@/assets/icons/question-mark.svg" alt=""></RouterLink>
      <button @click="logOut">wyloguj <img src="@/assets/icons/power.svg" alt=""></button>
    </div>
    <div class="profile-nav-container" v-if="currentLocation().includes('/dla-biznesu/employee-profile')">
      <RouterLink to="/dla-biznesu/employee-profile/user-data">Twoje dane</RouterLink>
      <RouterLink to="/dla-biznesu/employee-profile/report-error">Zgłoś błąd <img src="@/assets/icons/question-mark.svg" alt=""></RouterLink>
      <button @click="logOut">wyloguj <img src="@/assets/icons/power.svg" alt=""></button>
    </div>
    <div class="profile-nav-container" v-if="currentLocation().includes('/firm-profile')">
      <RouterLink to="/firm-profile">Twoje dane</RouterLink>
      <RouterLink to="/firm-profile/specialists">Specjaliści</RouterLink>
      <RouterLink to="/firm-profile/report-error">Zgłoś błąd <img src="@/assets/icons/question-mark.svg" alt=""></RouterLink>
      <button @click="logOut">wyloguj <img src="@/assets/icons/power.svg" alt=""></button>
    </div>
  </div>
</template>

<script>
import ButtonVue from './Button.vue';
import { useRoute } from 'vue-router';
export default{
  name:'NavigationVue',
  data(){
    return{

    }
  },
  props:{

  },
  components: {
    ButtonVue
  },
  computed:{
    currentUser() {
      return this.$store.state.auth.status.loggedIn
    },
    currentUserData() {
      return this.$store.state.auth.user;
    },
    isBiz(){
      if(!useRoute().fullPath.includes('/dla-biznesu')){
        return 'false'
      }else if(useRoute().fullPath.includes('/dla-biznesu')){
        return 'true'
      }else{
        return false
      }
    }
  },
  methods:{
    currentLocation(){
      return useRoute().fullPath
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped lang='scss'>
.router-link-active{
  font-weight: 600;
}

.nav-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  font-weight: lighter;
  img{
    width: 200px;
  }
  &__inner{
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    a{
      color: #000;
      text-decoration: none;
    }
    .account-link{
      display: flex;
      flex-direction: column;
      align-items: center;
      span{
        font-size: 1.5rem;
      }
    }
  }
}

.profile-nav-container{
  display: flex;
  gap: 25px;
  border-bottom: 2px solid #f2790f;
  padding-bottom: 10px;
  flex-wrap: wrap;
  a{
    text-decoration: none;
    color: #000;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  button{
    color: #ff0000;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
  }
}

@media(max-width: 900px){
  .nav-wrapper{
    flex-direction: column;
    gap: 20px;
    &__inner{
      gap: 10px;
      justify-content: center;
    }
  }
}
</style>
<template>
  <SearchBarVue/>
  <section class="statics">
    <h2>m2VET w liczbach</h2>
    <div class="statics__cont">
      <div class="statics__cont__top-card">
        <b>{{ Math.ceil(usersCount / 10)*10 }}</b>
        <span>specjalistów</span>
      </div>
      <div class="statics__cont__bottom-card">
        <b>98</b>
        <span>usług</span>
      </div>
    </div>
  </section>
  <section class="recommended-trainings">
    <TrainingsVue/>
  </section>
  <section class="highlighted-services">
    <h2>Wybrane usługi</h2>
    <ServiceModal/>
    <div class="highlighted-services__services-grid" v-if="randServices.length > 0 && categories.length > 0">
      <ServiceCardVue v-for="item in randServices" :is-advance="item.advanceAmount ? true : false" @openServiceModal="() => $router.push({path: '/', query: {serviceID: item.id}})" :price="item.price" :key="item.id" :img="item.photo_url" :name="item.name" :state="states.filter(state => state.value == item.state_id)[0].name" :ishighlighted="item.active" :category="categories.filter(category => category.id == item.user.category_id)[0].name"/>
    </div>
    <div v-else class="highlighted-services__services-grid">
      <h3>Brak usług</h3>
    </div>
  </section>
  <section class="recent-announcements">
    <h2>Ostatnie ogłoszenia użytkowników</h2>
    <div class="recent-announcements__announcements-list" v-if="announcements.length > 0">
      <AnnouncementCardVue :show-active="false" v-for="announcement in announcements.filter(item => Boolean(item.active) == true)" :key="announcement.id" :contact="announcement.contact" :target="announcement.target" :category="announcement.category" :img="announcement.photo_url" :longContent="announcement.description" :id="announcement.id" :title="announcement.title" :content="announcement.short_description" :photo="announcement.photo" />
      <ButtonVue @click="() => $router.push('/announcements')" color="#000" bgColor="#F27A07" text="Więcej ogłoszeń"/>
    </div>
    <div v-else class="highlighted-services__services-grid">
      <h3>Brak ogłoszeń</h3>
    </div>
  </section>
  <section class="partners">
    <h2>Nasi partnerzy</h2>
    <PartnersVue/>
  </section>
  <section class="recent-blog-posts" v-if="blogPosts">
    <h2>Ostatnie wpisy bloga</h2>
    <div class="recent-blog-posts__posts-grid">
      <BlogPostCardVue :id="post.id" :title="post.title" :target="post.target" :author="post.author" :photo="post.photo" :key="index" :created_at="post.created_at" v-for="post, index in blogPosts"/>
    </div>
  </section>
</template>

<script>
import { useMeta } from 'vue-meta';
import SearchBarVue from '@/components/HomeComponents/home/SearchBarVue.vue';
import TrainingsVue from '@/components/HomeComponents/home/Trainings.vue';
import ServiceCardVue from '@/components/SpecialComponents/ServiceCard.vue';
import AnnouncementCardVue from '@/components/SpecialComponents/AnnouncementCard.vue';
import PartnersVue from '@/components/HomeComponents/home/Partners.vue';
import BlogPostCardVue from '@/components/SpecialComponents/BlogPostCard.vue';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue'
import axios from 'axios';
import ButtonVue from '@/components/Button.vue';

export default {
  name: 'HomePage',
  data(){
    return{
      blogPosts: [],
      usersCount: 0,
      servicesCount: 0,
      randServices: [],
      states: [],
      announcements: [],
      categories: []
    }
  },
  setup () {
    useMeta({
      title: 'Strona główna',
      meta: [{ name: 'robots', Name: 'robots', content: 'all' }]
    })
  },
  components:{
    SearchBarVue,
    TrainingsVue,
    ServiceCardVue,
    AnnouncementCardVue,
    PartnersVue,
    BlogPostCardVue,
    ServiceModal,
    ButtonVue
},
  mounted () {
    this.getBlogPosts()
    this.getCounts()
    this.getRandomServices()
    this.getStates()
    this.getAnnouncements()
    this.getCategories()
  },
  methods:{
    getBlogPosts() {
      const params = {
        page: 1,
        limit: 1
      }
      axios.post('/BlogPostSearch', params).then(res => {
        axios.post('/BlogPostSearch', {
          page: Math.ceil(res.data.count/3),
          limit: 3
        }).then(resp => {
          resp.data.items.forEach((post) => {
          const ob = {
            title: post.title,
            photo: post.photo_url,
            author: post.author,
            target: post.target,
            created_at: post.created_at,
            id: post.id
          }
          this.blogPosts.push(ob)
        })
        })
      })
    },
    getCounts(){
      const params = {
        page: 1,
        limit: 1
      }
      axios.post('/Users', params).then(res => this.usersCount = res.data.count).catch(() => {})
      axios.post('/Services', params).then(res => this.servicesCount = res.data.count).catch(() => {})
    },
    getRandomServices(){
      axios.post('/Services', {page:1, limit:1}).then(res => {
        axios.post('/Services', {page: Math.floor(Math.random()*(res.data.count/3))+1, limit:3}).then(resp => {
          this.randServices = resp.data.items
        })
      })
    },
    getStates(){
        axios.get('/State').then(res => {
            res.data.forEach((item) => {
                const ob = {
                    name: item.name,
                    value: `${item.id}`
                }
                this.states.push(ob)
            })
        })
    },
    getAnnouncements(){
      axios.post('/AnnouncementSearch', {
        limit: 1,
        page: 1
      }).then(res => {
        axios.post('/AnnouncementSearch', {
          page: Math.floor(res.data.count/4),
          limit: 4
        }).then(resp => {
          this.announcements = resp.data.items
        })
      })
    },
    getCategories(){
      axios.get('/Categories').then(res => {
        res.data.forEach((item) => {
            const ob  = {
                name: item.name,
                id: `${item.id}`
            }
            this.categories.push(ob)
        })
      })
    }
  }
}
</script>

<style lang="scss">
.statics{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2{
    text-align: center;
  }
  &__cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 900px;
    gap: 30px;
    &__top-card{
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 170px;
      background-image: url('../assets/stat-img-1.png');
      background-position: center;
      background-size: cover;
      border-radius: 20px;
      font-size: 2.5rem;
      b{
        color: #F2790F;
        font-size: 4rem;
      }
    }
    &__bottom-card{
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 170px;
      background-image: url('../assets/stat-img-2.png');
      background-position: center;
      background-size: cover;
      border-radius: 20px;
      font-size: 2.5rem;
      b{
        color: #F2790F;
        font-size: 4rem;
      }
    }
  }
}

.recommended-trainings{
  margin-top: 100px;
}

.highlighted-services{
  margin-top: 50px;
  h2{
    text-align: center;
  }
  &__services-grid{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.recent-announcements{
  margin-top: 90px;
  h2{
    text-align: center;
  }
  &__announcements-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.partners{
  margin-top: 90px;
  h2{
    text-align: center;
  }
}

.recent-blog-posts{
  margin-top: 90px;
  h2{
    text-align: center;
  }
  &__posts-grid{
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
  }
}
</style>

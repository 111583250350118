<template>
  <div class="search-announcement" v-if="currentUser">
    <input type="text" placeholder="szukane ogłoszenie" id="announcementInput" v-model="announcementInput">
    <div class="target-choose">
      <select name="target" id="target" v-model="targetSelect" required>
        <option>do wszystkich</option>
        <option>do specjalistów</option>
        <option>do właścicieli zwierząt</option>
      </select>
      <select name="target2" id="target2" v-model="targetSelect2">
        <option>--wybierz--</option>
        <option>do wszystkich</option>
        <option>do specjalistów</option>
        <option>do właścicieli zwierząt</option>
      </select>
    </div>
    <select name="category" id="category" v-model="categorySelect" required>
      <option>sprzedaż</option>
      <option>współpraca</option>
      <option>wymiana</option>
      <option>inne</option>
    </select>
    <button @click="searchAnnouncement">szukaj</button>
    <button @click="() => {
      getAnnouncements()
      announcementInput = ''
      hidePages = false
      }">resetuj</button>
  </div>
  <p style="text-align: center;" v-if="!this.$store.state.auth.user">Aby dodać ogłoszenie załóż konto</p>
  <div class="announcement-page" v-if="!hidePages && currentUser">
    <button><img src="@/assets/message-arrow.png" @click="() => {
      if(announcementPage > 1){
        announcementPage -= 1
        getAnnouncements()
      }
    }" alt=""></button>
    <span>{{ announcementPage }}/{{ Math.ceil(announcementsCount/6) }}</span>
    <button @click="() => {
      if(announcementPage < Math.ceil(announcementsCount/6)){
        announcementPage += 1
        getAnnouncements()
      }
    }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
  </div>
  <div class="announcements-container" v-if="currentUser">
    <AnnouncementCardVue :show-active="false" v-for="announcement in announcements.filter(item => Boolean(item.active) == true)" :contact="announcement.contact" :target="announcement.target" :category="announcement.category" :key="announcement.id" :img="announcement.photo_url" :longContent="announcement.description" :id="announcement.id" :title="announcement.title" :content="announcement.short_description" :photo="announcement.photo" />
  </div>
  <div v-else class="not-logged-in">
    <h2>Zaloguj się aby przeglądać ogłoszenia</h2>
    <div>
        <ButtonVue text="Zaloguj się" bg-color="#F37905" color="#000" @click="() => $router.push('/login')"/>
        <ButtonVue text="Zarejestruj się" bg-color="#F37905" color="#000" @click="() => $router.push('/register')"/>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import ButtonVue from '@/components/Button.vue'
import AnnouncementCardVue from '@/components/SpecialComponents/AnnouncementCard.vue';
import axios from 'axios';
export default{
    name:"AnnouncementsPage",
    setup () {
        useMeta({title: 'Ogłoszenia'})
    },
    data(){
        return{
            announcements: [],
            announcementPage: 1,
            announcementsCount: null,
            announcementsFound: [],
            announcementInput: '',
            hidePages: false,
            targetSelect: 'do wszystkich',
            categorySelect: 'sprzedaż',
            targetSelect2: '--wybierz--'
        }
    },
    props:{

    },
    computed: {
        currentUser() {
            // return this.$store.state.auth.user;
            return true;
        }
    },
    mounted(){
        this.getAnnouncements()
    },
    methods:{
        searchAnnouncement(){
        this.hidePages = true
        if(this.announcementInput.length > 2){
          axios.post('/AnnouncementSearch', {
              page: 1,
              limit: 1000,
          }).then(res => {
              if(res.data.count > 1000){
                  axios.post('/AnnouncementSearch', {
                      page: 1,
                      limit: res.data.count,
                  }).then(resp => { 
                      this.announcements = resp.data.items.filter(item => item.title.toLowerCase().includes(this.announcementInput.toLowerCase()) && `${this.targetSelect} ${this.targetSelect2}`.includes(item.target) && item.category == this.categorySelect)
                  })
              }else{
                this.announcements = res.data.items.filter(item => item.title.toLowerCase().includes(this.announcementInput.toLowerCase()) && `${this.targetSelect} ${this.targetSelect2}`.includes(item.target) && item.category == this.categorySelect)
              }
          })
        }else{
          this.$store.commit('setIsAlert', 'wpisz co najmniej 3 litery')
        }
      },
        getAnnouncements(){
            this.announcements = []
            const ob = {
                page: this.announcementPage,
                limit: 6
            }
            axios.post('/AnnouncementSearch', ob).then(res => {
                this.announcementsCount = res.data.count
                res.data.items.forEach(item => {
                    const freshAnnouncement = {
                        title: item.title,
                        description: item.description,
                        id: item.id,
                        photo_url: item.photo_url,
                        short_description: item.short_description,
                        target: item.target,
                        category: item.category,
                        contact: item.contact,
                        active: item.active
                    }
                    this.announcements.push(freshAnnouncement)
                })
            })
        }
    },
    components:{ ButtonVue, AnnouncementCardVue }
}
</script>

<style scoped lang="scss">
.not-logged-in{
    display: flex;
    flex-direction: column;
    align-items: center;
    div{
        display: flex;
        justify-content: center;
        gap: 40px;
    }
}

.announcement-page{
  display: flex;
  gap: 10px;
  button{
    background-color: #f37905;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    img{
      width: 15px;
      height: 15px;
    }
    img.rotate{
      transform: rotate(180deg);
    }
  }
}

.search-announcement{
  background-color: #e4e4e4;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
  justify-content: center;
  *{
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
  }
  button{
    cursor: pointer;
  }
  div.target-choose{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.announcements-container{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}
</style>
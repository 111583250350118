<template>
<div v-if="currentUser" class="users-download">
    <h2>Pobierz użytkowników do plik CSV</h2>
    <form @submit="(e) => downloadUsers(e)">
        <h3>Kryteria pobierania</h3>
        <label for="stateSelect">Województwo</label>
        <select name="stateSelect" id="stateSelect" v-model="searchParams.state_id">
            <option value="">wszystkie</option>
            <option :value="state.id" v-for="state in states" :key="state.id">{{ state.name }}</option>
        </select>

        <label for="categorySelect">Kategoria</label>
        <select name="categorySelect" id="categorySelect" v-model="searchParams.category_id">
            <option value="">wszystkie</option>
            <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
        </select>

        <label for="subscriptionSelect">Subskrypcja</label>
        <select name="subscriptionSelect" id="subscriptionSelect" v-model="searchParams.subscription_id">
            <option value="">wszystkie</option>
            <option :value="sub.id" v-for="sub in subscriptions" :key="sub.id">{{ sub.name }}</option>
        </select>

        <label for="haveSentSms">Ma wysłane SMSy</label>
        <div>
            <input type="radio" id="haveSentSms" :value="true" v-model="searchParams.have_sent_sms">
            <label for="haveSentSms">Tak</label>
        </div>
        <div>
            <input type="radio" id="haventSentSms" :value="false" v-model="searchParams.have_sent_sms">
            <label for="haventSentSms">Nie</label>
        </div>

        <label for="city">Miasto</label>
        <input type="text" id="city" name="city" v-model="searchParams.city">

        <ButtonVue text="Pobierz" bg-color="#5EA01A"/>
    </form>
</div>
</template>

<script>
import authHeader from '@/services/auth-header';
import ButtonVue from '@/components/Button.vue';
import axios from 'axios';

export default{
name: "UsersDownload",
data() {
    return {
        states: [],
        categories: [],
        subscriptions: [],
        searchParams: {
            state_id: null,
            role_id: 2,
            have_sent_sms: true,
            sortAb: true,
            city: '',
            subscription_id: null,
            category_id: null
        }
    };
},
props: ['isVisible'],
mounted() {
    if (!this.currentUser) {
        this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
        this.$router.push('/login');
    }
    axios.get(`/Categories`).then(res => {
        this.categories = res.data
    })
    axios.get(`/State`).then(res => {
        this.states = res.data
    })
    axios.post('/SubscriptionSearch', {
        page: 1,
        limit: 10 
    }).then(res => {
        this.subscriptions = res.data.items
    }).catch(() => {
        
    })
},
computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
},
methods: {
    downloadUsers(e){
        e.preventDefault()
        const config = {
            responseType: 'blob',
            headers: { 
                'Authorization': `${authHeader().headers.Authorization}`,
                'Content-Type': 'application/json',
                'Content-Disposition': 'application/json'
            } 
        }
        axios.post('/UsersCSVList', this.searchParams, config).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', 'Users_lists.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(() => {
            this.$store.commit('setIsAlert', 'Brak użytkowników spełniających kryteria')
        })
    }
},
components: {ButtonVue}
}
</script>

<style scoped>
.users-download > form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.users-download > form > label,button{
    margin-top: 20px;
}

.users-download > form > input[type="text"]{
    border-radius: 5px;
    border: 1px solid #797979;
    padding: 5px;
}

.users-download > form > select{
    border-radius: 5px;
    border: 1px solid #797979;
    padding: 5px;
}
</style>
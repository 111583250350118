<template>
    <div class="user-subscriptions-wrapper" v-if="currentUser">
        <BuyPlanModal :plans="subscriptions" :userEmail="user.email"/>
        <div class="user-subscriptions-wrapper__header">
            <h2>Subskrypcje</h2>
            <button :style="{fontWeight: view == '0' ? 'bolder' : 'normal'}" @click="() => view = '0'" v-if="!user.subscription">Pakiety</button>
            <button :style="{fontWeight: view == '1' ? 'bolder' : 'normal'}" @click="() => view = '1'">Aktywne subskrypcje</button>
            <div v-if="user.payments_history">
                <button :style="{fontWeight: view == '2' ? 'bolder' : 'normal'}" @click="() => view = '2'" v-if="user.payments_history.length > 0">Historia płatności</button>
            </div>
            <button v-if="user.subscription" :style="{fontWeight: view == '3' ? 'bolder' : 'normal'}" @click="() => view = '3'">SMSy</button>
        </div>
        <div v-if="view == '0'" class="user-subscriptions-wrapper__packets-page">
            <div class="heading">
                <span role="button" @click="() => currentPacketCategory = 'abonaments'" :style="{fontWeight: currentPacketCategory == 'abonaments' ? 'bolder' : 'normal'}">Abonamenty</span>
            </div>
            <div v-if="currentPacketCategory == 'abonaments'" class="user-subscriptions-wrapper__packets-page__abonaments">
                <SubPlanCard v-for="sub in subscriptions" @refreshSubscriptions="getSubscriptions" :id="sub.id" :name="sub.name" :key="sub.id" :subscription-m="sub.subscriptionM" :additional-s-m-s="sub.additionalSMS" :additional-user-m="sub.additionalUserM" :free-users="sub.freeUsers" :free-s-m-s="sub.freeSMS" :period="sub.period" :admin="true"/>
            </div> 
        </div>
        <div v-if="view == '1'" class="user-subscriptions-wrapper__active-page">
            <div class="user-subscriptions-wrapper__active-page__item">
                <div v-if="!user.subscription">
                    <span>Brak aktywnych subskrypcji</span>
                </div>
                <div v-if="user.subscription">
                    <span>{{ user.subscription.subscription.name }}</span>
                </div>   
                <span v-if="!user.subscription" class="label">aby w pełni korzystać z serwisu, wykup subskrypcję</span>
                <span v-if="user.subscription" class="label">status</span>
                <span v-if="user.subscription" class="value">Aktywny <img src="@/assets/icons/active-dot.svg"></span>
                <span v-if="user.subscription" class="label">Data wygaśnięcia</span>
                <span v-if="user.subscription" class="value">{{ user.subscription.date }}</span>
                <span v-if="user.subscription" class="label">Dodatkowi pracownicy</span>
                <span v-if="user.subscription" class="value">{{ user.subscription.users ? user.subscription.users : '0' }}</span>
                <button @click="isExpanding = true" v-if="user.subscription">Rozszerz plan</button>
                <div class="expand-plan" v-if="isExpanding">
                    <label for="additionalUsers">Ilość użytkowników do dodania</label>
                    <input type="number" v-model="additionalUsers" min="1">
                    <span>Pozostały czas: {{ getLeftPLanMonths() }} mies.</span>
                    <span>Cena za dodatkowego użytkownika: {{ user.subscription.subscription.additionalUserM }} zł</span>
                    <span>Cena: {{ additionalUsers*user.subscription.subscription.additionalUserM*getLeftPLanMonths() }} zł</span>
                    <button @click="expandPlan()">Zatwierdź</button>
                </div>
            </div>
        </div>
        <div v-if="view == '2' && user.payments_history" class="user-subscriptions-wrapper__active-page">
            <div v-for="sub in user.payments_history" :key="sub.id" class="user-subscriptions-wrapper__active-page__item">
                <div>
                    <span>{{ subscriptions.find(item => item.id == sub.subscription_id).name }}</span>
                </div>   
                <span class="label">status</span>
                <span class="value">Nieaktywny <img src="@/assets/icons/non-active-dot.svg"></span>
                <span class="label">Od</span>
                <span class="value">{{ sub.date_from }}</span>
                <span class="label">Do</span>
                <span class="value">{{ sub.date_to }}</span>
            </div>
        </div>
        <div v-if="view == '3'">
            <div class="sms-counter">
                <h3>Licznik wysłanych SMSów:</h3>
                <h4>{{ user.sent_sms ? user.sent_sms : '0' }} sms</h4>
                <h4>{{ user.sent_sms ? user.sent_sms*user.subscription.additionalSMS : '0' }} zł</h4>
            </div>
            <div>
                <h3>Powiadomienia sms</h3>
                <div class="switch-container">
                    <span>wył.</span>
                    <label class="switch">
                        <input type="checkbox" @change="smsBlockSwitch" :checked="user.sending_sms_active === null || user.sending_sms_active ? true : false">
                        <span class="slider round"></span>
                    </label>
                    <span>wł.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import SubPlanCard from '@/components/SpecialComponents/SubPlanCard.vue'
import axios from 'axios'
import BuyPlanModal from '@/components/SpecialComponents/BuyPlanModal.vue'
import authHeader from '@/services/auth-header'

export default{
  name:'UserSubscriptions',
  setup () {
    useMeta({title: 'Subskrypcje'})
  },
  data(){
    return{
        view: '0',
        currentPacketCategory: 'abonaments',
        subscriptions: [],
        user: {},
        additionalUsers: 1,
        isExpanding: false
    }
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 2) {
      this.$router.push('/login');
    }
    this.getSubscriptions()
    this.getUserData()
  },
  props:{

  },
  methods:{
    getSubscriptions(){
        axios.post('/SubscriptionSearch', {
            page: 1,
            limit: 100 
        }).then(res => {
            this.subscriptions = res.data.items
        }).catch(() => {
            
        })
    },
    getUserData(){
        axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
            this.user = res.data
            this.user.payments_history.pop()
            if(this.user.subscription){
               this.view = '1' 
            }
        }).catch(() => {
            
        })
    },
    expandPlan(){
        this.isExpanding = false
      const ob = {
        payment_name: `Rozszerz plan: ${this.user.subscription.subscription.name}`,
        subscription_id: this.user.subscription.subscription.id,
        users: this.additionalUsers,
        buyer_email: this.user.email,
        only_update: true
      }
      axios.post('/GetPaymentUrl', ob, authHeader()).then(res => {
        window.location.replace(res.data.payment_url)
      })
    },
    getLeftPLanMonths(){
        let expirationDate = new Date(this.user.subscription.date)
        let today = new Date()

        let difference = expirationDate.getTime() - today.getTime()
        let differenceInDays = Math.round(difference / (1000*3600*24))
        let differenceInMonths = Math.ceil(differenceInDays/30)
        return differenceInMonths
    },
    smsBlockSwitch(){
        const currentState = this.user.sending_sms_active === null || this.user.sending_sms_active ? true : false
        axios.post('/SwitchSmsBlock', {
            sending_sms_active: !currentState
        }, authHeader()).then(res => {
            if(String(res.status).charAt(0) === '2'){
                this.$store.commit('setIsSuccess', currentState ? 'zablokowano wysyłanie sms' : 'odblokowano wysyłanie sms')
                this.user.sending_sms_active = !currentState
            }
        })
    }
  },
  components: { SubPlanCard, BuyPlanModal }
}
</script>

<style scoped lang='scss'>
.switch-container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #F2790F;
}

input:focus + .slider {
  box-shadow: 0 0 1px #F2790F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sms-counter{
    h4{
        margin: 5px 0;
    }
}
.user-subscriptions-wrapper{
    margin-top: 20px;
    &__header{
        display: flex;
        align-items: center;
        gap: 40px;
        flex-wrap: wrap;
        h2{
            margin: 10px 20px;
        }
        button{
            padding: 15px 30px;
            text-align: left;
            border: none;
            background-color: #F3F3F3;
            box-shadow: -1px 1px 7px 0px #bdbdbd;
            border-radius: 10px;
            cursor: pointer;
        }
    }
    &__packets-page{
        div.heading{
            display: flex;
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            gap: 40px;
            span{
                text-align: center;
                border-bottom: 2px solid #F2790F;
                padding-bottom: 10px;
                width: calc(50% - 20px);
                min-width: 250px;
                font-size: 2.3rem;
                margin: 30px 0;
                cursor: pointer;
            }
        }
        &__abonaments{
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &__active-page{
        margin-top: 40px;
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        justify-content: flex-start;
        h3{
            margin: 0 20px;
        }
        &__item{
            width: 90%;
            max-width: 270px;
            min-width: 230px;
            border: none;
            background-color: #F3F3F3;
            box-shadow: -1px 1px 7px 0px #bdbdbd;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            div:not(.expand-plan){
                width: 100%;
                border-radius: 10px 10px 0 0;
                background-color: #F2790F;
                padding: 15px 0;
                display: flex;
                justify-content: center;
            }
            span.label{
                font-size: 1.7rem;
                margin: 0 10px;
                margin-top: 15px;
            }
            span.value{
                margin: 0 10px;
            }
            button{
                margin: 0 10px;
                margin-top: 15px;
                background-color: #F2790F;
                border: none;
                border-radius: 8px;
                font-size: 1.7rem;
                padding: 7px 0;
                cursor: pointer;
            }
            .expand-plan{
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                input{
                    padding: 5px;
                    border-radius: 8px;
                    border: 1px solid #bdbdbd;
                }
                span{
                    font-size: 1.7rem;
                }
            }
        }
    }
}
</style>
<template>
  <div class="search-entry" v-if="currentUser">
    <input type="text" placeholder="szukany post" id="entryInput" v-model="entryInput">
    <select name="entryCategory" id="entryCategory" v-model="entryCategory">
      <option value="">-- wybierz kategorię --</option>
      <option :value="category.id" v-for="category, index in categories" :key="index">{{ category.title }}</option>
    </select>
    <select name="entryTarget" id="entryTarget" v-model="entryTarget">
        <option>do wszystkich</option>
        <option>do specjalistów</option>
        <option>do właścicieli zwierząt</option>
    </select>
    <button @click="searchEntry">szukaj</button>
    <button @click="() => {
      getBlogPosts()
      entryInput = ''
      entryCategory = ''
      hidePages = false
      showLatestBlogEntries = true
      }">resetuj</button>
  </div>
  
  <h2 v-if="currentUser && popularBlogPosts.length > 0" class="latest-entries-control">Popularne wpisy <button @click="() => showPopularBlogEntries = !showPopularBlogEntries"><img :class="!showPopularBlogEntries ? 'closed' : null" src="@/assets/message-arrow.png"></button></h2>
  <div class="latest-blog-container" v-if="currentUser && showPopularBlogEntries && popularBlogPosts.length > 0">
    <BlogPostCardVue :id="post.id" :target="post.target" :title="post.title" :author="post.author" :photo="post.photo_url" :key="index" :created_at="post.created_at" v-for="post, index in popularBlogPosts"/>
  </div>

  <h2 v-if="currentUser" class="latest-entries-control">Najnowsze wpisy <button @click="() => showLatestBlogEntries = !showLatestBlogEntries"><img :class="!showLatestBlogEntries ? 'closed' : null" src="@/assets/message-arrow.png"></button></h2>
  <div class="latest-blog-container" v-if="currentUser && showLatestBlogEntries">
    <BlogPostCardVue :id="post.id" :target="post.target" :title="post.title" :author="post.author" :photo="post.photo_url" :key="index" :created_at="post.created_at" v-for="post, index in latestBlogPosts"/>
  </div>

  <div class="entry-page" v-if="!hidePages && currentUser">
    <button @click="() => {
      if(entryPage > 1){
        entryPage -= 1
        getBlogPosts()
      }
    }" ><img src="@/assets/message-arrow.png" alt=""></button>
    <span>{{ entryPage }}/{{ Math.ceil(entriesCount/6) }}</span>
    <button @click="() => {
      if(entryPage < Math.ceil(entriesCount/6)){
        entryPage += 1
        getBlogPosts()
      }
    }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
  </div>
  <div class="blog-container" v-if="currentUser">
    <!-- <EntryModalVue/> -->
    <BlogPostCardVue :id="post.id" :target="post.target" :title="post.title" :author="post.author" :photo="post.photo_url" :key="index" :created_at="post.created_at" v-for="post, index in blogPosts"/>
  </div>
  <div style="justify-content: flex-end;" class="entry-page" v-if="!hidePages && currentUser">
    <button @click="() => {
      if(entryPage > 1){
        entryPage -= 1
        getBlogPosts()
      }
    }"><img src="@/assets/message-arrow.png" alt=""></button>
    <span>{{ entryPage }}/{{ Math.ceil(entriesCount/6) }}</span>
    <button @click="() => {
      if(entryPage < Math.ceil(entriesCount/6)){
        entryPage += 1
        getBlogPosts()
      }
    }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
  </div>
  <div v-else class="not-logged-in">
    <h2>Zaloguj się aby skorzystać z bloga</h2>
    <div>
        <ButtonVue text="Zaloguj się" bg-color="#F37905" color="#000" @click="() => $router.push('/login')"/>
        <ButtonVue text="Zarejestruj się" bg-color="#F37905" color="#000" @click="() => $router.push('/register')"/>
    </div>
  </div>
</template>

<script>
import ButtonVue from '@/components/Button.vue'
import { useMeta } from 'vue-meta'
import BlogPostCardVue from '@/components/SpecialComponents/BlogPostCard.vue'
import axios from 'axios'
// import EntryModalVue from '@/components/SpecialComponents/EntryModal.vue'

export default{
  name:'BlogVue',
  setup () {
    useMeta({
      title: 'Blog',
      meta: [{ name: 'robots', Name: 'robots', content: 'all' }]
    })
  },
  data(){
    return{
      blogPosts: [],
      categories: [],
      selectedCategory: '',
      entryPage: 1,
      entriesCount: null,
      entriesFound: [],
      entryCategory: '',
      entryInput: '',
      hidePages: false,
      entryTarget: 'do wszystkich',
      latestBlogPosts: [],
      showLatestBlogEntries: true,
      showPopularBlogEntries: true,
      popularBlogPosts: []
    }
  },
  props:{

  },
  computed: {
    currentUser() {
        // return this.$store.state.auth.user;
        return true;
    }
  },
  mounted(){
    // this.checkEntriesCount()
    axios.post('/BlogPostSearch', { page: 1,limit: 1}).then(res => this.entriesCount = res.data.count).then(() => {
      // this.entryPage = Math.ceil(this.entriesCount/6)
      // this.getBlogPosts(Math.ceil(this.entriesCount/6))
      this.getBlogPosts()
      this.getCategories()
      this.getLatestEntries()
      this.getPopularBlogPosts()
    })
  },
  components:{ ButtonVue, BlogPostCardVue, 
    // EntryModalVue
  },
  methods:{
    getBlogPosts(startPage) {
      this.blogPosts = []
        const params = {
          page: startPage ? startPage : this.entryPage,
          limit: 6
        }
        axios.post('/BlogPostSearch', params).then(res => {
          res.data.items.forEach((post) => {
            const ob = {
              title: post.title,
              photo_url: post.photo_url,
              author: post.author,
              id: post.id,
              target: post.target,
              created_at: post.created_at
            }
            this.blogPosts.push(ob)
          })
        }).catch(() => {
            
        })
    },
    getCategories(){
      axios.post('/BlogCategorySearch', {
          page: 1,
          limit: 1000
      }).then(res => {
          if(res.data.count > 1000){
              axios.post('/BlogCategorySearch', {
                  page: 1,
                  limit: res.data.count
              }).then(resp => {
                this.categories = resp.data.items
              })
          }else{
            this.categories = res.data.items
          }
      })
      },
      searchEntry(){
        this.hidePages = true
        this.showLatestBlogEntries = false
        if(this.entryInput.length > 2 || this.entryCategory || this.entryTarget){
          axios.post('/BlogPostSearch', {
              page: 1,
              limit: 1000,
              blog_category_id: this.entryCategory
          }).then(res => {
              if(res.data.count > 1000){
                  axios.post('/BlogPostSearch', {
                      page: 1,
                      limit: res.data.count,
                      blog_category_id: this.entryCategory
                  }).then(resp => { 
                      this.blogPosts = resp.data.items.filter(item => item.title.toLowerCase().includes(this.entryInput.toLowerCase()) && item.target == this.entryTarget)
                  })
              }else{
                this.blogPosts = res.data.items.filter(item => item.title.toLowerCase().includes(this.entryInput.toLowerCase()) && item.target == this.entryTarget)
              }
          })
        }else{
          this.$store.commit('setIsAlert', 'wpisz co najmniej 3 litery')
        }
      },
      getLatestEntries(){
        this.latestBlogPosts = []
        axios.post('/BlogPostSearch', {
          page: Math.ceil(this.entriesCount/3),
          limit: 3
        }).then(res => {
          res.data.items.forEach((post) => {
            const ob = {
              title: post.title,
              photo_url: post.photo_url,
              author: post.author,
              id: post.id,
              target: post.target,
              created_at: post.created_at
            }
            this.latestBlogPosts.push(ob)
          })
        }).catch(() => {
            
        })
      },
      getPopularBlogPosts(){
        this.popularBlogPosts = []
        axios.post('/BlogPostSearch', {
          page: 1,
          limit: 3,
          popular: true
        }).then(res => {
          res.data.items.forEach((post) => {
            const ob = {
              title: post.title,
              photo_url: post.photo_url,
              author: post.author,
              id: post.id,
              target: post.target,
              created_at: post.created_at
            }
            this.popularBlogPosts.push(ob)
          })
        }).catch(() => {
            
        })
      }
  },
}
</script>

<style scoped lang='scss'>
.not-logged-in{
    display: flex;
    flex-direction: column;
    align-items: center;
    div{
        display: flex;
        justify-content: center;
        gap: 40px;
    }
}

.entry-page{
  display: flex;
  gap: 10px;
  button{
    background-color: #f37905;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    img{
      width: 15px;
      height: 15px;
    }
    img.rotate{
      transform: rotate(180deg);
    }
  }
}

.search-entry{
  background-color: #e4e4e4;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
  justify-content: center;
  *{
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
  }
  button{
    cursor: pointer;
  }
}

.blog-container{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}
.latest-blog-container{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.latest-entries-control{
  display: flex;
  align-items: center;
  gap: 20px;
  button{
    background: none;
    border: none;
    height: 25px;
    cursor: pointer;
    img{
      transform: rotate(-90deg);
      width: 25px;
    } 
    img.closed{
      transform: rotate(90deg);
    }
  }
}
</style>
<template>
  <div v-if="$route.query.serviceID" class="modal-wrap">
    {{ getServiceData() }}
    <div class="service-modal-bg" v-if="serviceData && serviceData.gotUserData"></div>
    <div v-if="serviceData && serviceData.gotUserData" class="service-modal-container">
      <button class="service-modal-container__close-btn" @click="() => {
        $router.push(`${$route.path}`).then(() => serviceData = null)
      }"><img src="@/assets/icons/x-white.svg" alt=""></button>
      <div class="service-modal-container__img" :style="{ 'background-image': 'url(' + this.serviceData.photo_url + ')' }"></div>
      <div class="service-modal-container__conent">
        <div class="service-modal-container__conent__top">
          <div v-if="currentUser && $route.path !== '/profile/fav-services'">
            <button v-if="currentUser.data.role == 3" class="service-modal-container__conent__top__fav-btn" @click="setFav()"><img src="@/assets/icons/heart.svg" alt="dodaj do ulubionych"></button>
          </div>
          <div>
            <h3>{{ serviceData.name }}</h3>
            <router-link :to="`/browse/user/${serviceData.gotUserData.id}`">{{ serviceData.gotUserData.name }}</router-link>
          </div>
          <span class="service-modal-container__conent__top__price">{{ serviceData.price }} zł</span>
          <div>
            <button v-if="!noScheduleBtn" class="service-modal-container__conent__top__btn" :style="serviceData.advanceAmount ? 'margin-top: 20px' : null" @click="() => $router.push({path: '/profile/make-appointment', query: {serviceid: serviceData.id}})">Umów wizytę</button>
            <span v-if="serviceData.advanceAmount" class="advance-info">Zaliczka <img src="@/assets/icons/cash.svg" alt="">{{ serviceData.advanceAmount }}%</span>
          </div>
        </div>
        <div class="service-modal-container__conent__bottom">
          <div class="service-modal-container__conent__bottom__left">
            <span>{{ serviceData.gotUserData.category.name }}</span>
            <span>{{ serviceData.address }}</span>
            <span>{{ serviceData.phone }}</span>
            <span class="employee" v-if="serviceData.gotEmployeeData" @click="() => $router.push(`/browse/user/${serviceData.gotEmployeeData.id}`)"><img src="@/assets/icons/person-fill.svg" alt="">{{ serviceData.gotEmployeeData.firstname }} {{ serviceData.gotEmployeeData.lastname }}</span>
            <span style="color: #ff0000; cursor: pointer;" @click="() => report()">zgłoś</span>
          </div>
          <div class="service-modal-container__conent__bottom__center"></div>
          <div class="service-modal-container__conent__bottom__right">
            <p>{{ serviceData.short_description }}</p>
            <p style="font-size: 1.7rem;" v-html="serviceData.description.replace(/\n/g, '<br>')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import axios from 'axios';
export default{
  name:'ServiceModal',
  data(){
    return{
        serviceData: null
    }
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  props:['noScheduleBtn'],
  methods:{
    getServiceData(){
        if(!this.serviceData){
            axios.get(`/Service/${this.$route.query.serviceID}`).then(res => {
                this.serviceData = res.data
                if(this.serviceData){
                  axios.get(`/User/${res.data.user_id}`).then(resp => {
                      this.serviceData.gotUserData = resp.data
                  }).catch(() => {
                      
                  })
  
                  axios.get(`/User/${res.data.employee_id}`).then(resp => {
                    this.serviceData.gotEmployeeData = resp.data
                  }).catch(() => {
                      
                  })
                }else{
                  this.$store.commit('setIsAlert', 'nie ma już takiej usługi')
                }
            })
        }
    },
    report(){
      const route = this.$router.resolve({path: '/report', query: {label: `usługę: ${this.serviceData.name}`, id: this.serviceData.id, property: 'service_id'}})
      window.open(route.href, '_blank')
    },
    setFav(){
      axios.post('/UserService', {
        user_id: this.currentUser.data.user_id,
        service_id: this.$route.query.serviceID
      }, authHeader()).then(() => {
        this.$store.commit('setIsSuccess', 'Dodano do ulubionych. Usługę znajdziesz w panelu konta')
      })
    }
  }
}
</script>

<style scoped lang='scss'>
  .service-modal-container::-webkit-scrollbar{
    width: 8px;
  }
  .service-modal-container::-webkit-scrollbar-track {
    background: #fff; 
  }
  .service-modal-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px;
  }
  .service-modal-bg{
    position: fixed;
    width: 100vw;
    height: calc(100vh + 10px);
    background-color: #000;
    opacity: 0.8;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
.service-modal-container{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  // max-width: 1200px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #B1B1B1;
  z-index: 100;
  // overflow-y: scroll;
  overflow: hidden;
  padding-bottom: 20px;
  // max-height: 530px;
  height: 90vh;
  &__img{
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
  }
  &__close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    border-radius: 50%;
    background-color: #000000;
    width: 35px;
    height: 35px;
    cursor: pointer;
    img{
      transform: translate(-4px, 2px);
      width: 30px;
      height: 30px;
    }
  }
  &__conent{
    display: flex;
    flex-direction: column;
    &__top{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      div{
        display: flex;
        flex-direction: column;
        align-items: center;
        h3{
          margin: 10px;
        }
      }
      &__price{
        padding: 10px 20px;
        background-color: #F2790F;
        border-radius: 8px;
      }
      &__btn{
        padding: 10px 12px;
        background-color: #F2790F;
        border-radius: 8px;
        border: none;
        cursor: pointer;
      }
      &__fav-btn{
        background: none;
        border: none;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
    &__bottom{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      gap: 10px;
      max-height: 230px;
      overflow-y: scroll;
      flex-wrap: wrap;
      margin-right: 5px;
      &__left{
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 200px;
        font-size: 1.7rem;
        align-items: flex-end;
        text-align: right;
        .employee{
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &__center{
        width: 2px;
        background-color: #B1B1B1;
      }
      &__right{
        width: calc(95% - 200px);
        min-width: 250px;
      }
    }
    &__bottom::-webkit-scrollbar{
    width: 8px;
    }
    &__bottom::-webkit-scrollbar-track {
      background: #fff; 
    }
    &__bottom::-webkit-scrollbar-thumb {
      background: #888; 
      border-radius: 4px;
  }
  }
}

.advance-info{
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  img{
    width: 20px;
  }
}

@media (max-width: 570px){
  .service-modal-container{
    &__conent{
      &__top{
        flex-wrap: wrap;
      }
      &__bottom{
        &__center{
          display: none;
        }
        &__left{
          align-items: center;
          text-align: center;
          border-bottom: 2px dashed #B1B1B1;
          padding-bottom: 20px;
        }
        &__right{
          width: 90%;
          min-width: 0;
        }
      }
    }
  }
}
</style>
<template>
      <header class="header-container">
        <img src="@/assets/grafika_wyszukiwarka.png" alt="" class="header-container__header-img">
        <div class="header-container__hsearch-bar">
            <InputVue type="text" placeholder="Szukana usługa" id="searchBarInput" v-model="name"/>
            <DropdownVue :items="states" placeholder="województwo" id="stateDropdown" @setChecked="(e) => state = e"/>
            <ButtonVue bgColor="#fff" color="gray" text="Wyszukaj" @click="() => {
            if(`${state}${currentCategory}${name}` == 'placeholder'){
              $store.commit('setIsAlert', 'podaj chociaż jedno kryterium')
            }else{
              if(name.length > 2 || name.length == 0){
                setSearchParams()
              }else{
                $store.commit('setIsAlert', 'wpisz minimum 3 litery')
              }
            }
            }"/>
            <p style="padding: 0; margin: 0;" v-if="currentCategory">Wybrana kategoria: <b>{{ categories.find(item => item.id == currentCategory).name }}</b></p>
        </div>
        <div class="header-container__hservices-list">
            <div class="header-container__hservices-list__srv-cont" v-for="category in categories" :key="category.id" @click="() => currentCategory = category.id">
                <span class="header-container__hservices-list__srv-cont__srv-span" :style="{'text-decoration': `${currentCategory == category.id ? 'underline' : 'none'}`}">{{ category.name }}</span>
            </div>
        </div>
        <!-- <div class="header-container__user-hsearch-bar">
          <span>Wyszukaj specjalistę</span>
          <div>
            <InputVue type="text" placeholder="Nazwa/imię i nazwisko" id="searchBarInputSpecName" v-model="specName"/>
            <DropdownVue :items="categories" placeholder="specjalizacja" id="specDropdown" @setChecked="(e) => specCategory = e"/>
            <ButtonVue bgColor="#fff" color="gray" text="Wyszukaj"/>
          </div>
        </div> -->
        <ButtonVue style="margin-top: 30px; font-size: 2.2rem;" bgColor="#f27a07" @click="() => $router.push('/spec-browser')" color="#000" text="Wyszukaj specjalistę"/>
    </header>
</template>

<script>
import axios from 'axios';
import ButtonVue from '../../Button.vue'
import DropdownVue from '@/components/Dropdown.vue';
import InputVue from '@/components/Input.vue';

export default{
  name:'SearchBarVue',
  data(){
    return{
      states: [],
      categories: [],
      currentCategory: '',
      state: 'placeholder',
      name: '',
      specName: '',
      specCategory: ''
    }
  },
  props:{

  },
  components: {
    ButtonVue,
    DropdownVue,
    InputVue
  },
  mounted(){
    this.getStatesAndCategories()
  },
  methods:{
    getStatesAndCategories(){
      axios.get('/State').then(res => {
        res.data.forEach((item) => {
            const ob = {
                name: item.name,
                value: `${item.id}`
            }
            this.states.push(ob)
        })
      })

      axios.get('/Categories').then(res => {
        res.data.forEach((item) => {
            const ob  = {
                name: item.name,
                id: `${item.id}`
            }
            this.categories.push(ob)
        })
      })
    },
    setSearchParams(){
      this.$router.push({path: '/browser', query: {name: this.name, state: this.state, category: this.currentCategory}})
    },
  }
}
</script>

<style scoped lang='scss'>
.header-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__header-img{
        max-width: 700px;
        width: 100%;
    }
    &__hsearch-bar{
        background-color: #5EA01A;
        padding: 30px;
        border-radius: 20px;
        max-width: 800px;
        display: flex;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;
    }
    &__hservices-list{
        display: flex;
        max-width: 700px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        margin-top: 15px;
        // font-size: 1.8rem;
        &__srv-cont{
            &__srv-span{
                cursor: pointer;
            }   
        }
    }
    &__user-hsearch-bar{
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      background-color: #5EA01A;
      padding: 30px;
      border-radius: 20px;
      max-width: 800px;
      div{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
      }
    }
}

@media(max-width: 350px){
  .header-container{
    &__hsearch-bar{
      padding: 15px;
    }
  }
}
</style>
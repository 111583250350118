<template>
    <h2>Dodaj pracownika</h2>
    <div class="add-employee-wrapper">        
        <form class="add-employee-wrapper__form" @submit="(e) => addNewEmployee(e)">
            <input type="text" placeholder="imię" name="firstname" id="firstname" v-model="employee.firstname" required maxlength="50" minlength="3" autocomplete="name">
            <input type="text" placeholder="nazwisko" name="lastname" id="lastname" v-model="employee.lastname" required maxlength="70" minlength="3" autocomplete="family-name">
            <input type="text" placeholder="adres" name="address" id="address" v-model="employee.address" maxlength="50" minlength="3" autocomplete="street-address">
            <input type="tel" placeholder="numer telefonu" name="phone" id="phone" v-model="employee.phone" pattern="[0-9]{9}" autocomplete="tel">
            <input type="email" placeholder="adres e-amil" name="email" id="email" v-model="employee.email" required autocomplete="email">
            <input type="password" placeholder="hasło" name="pass" id="pass" v-model="employee.password" required autocomplete="new-password">
            <input type="file" name="photo" id="photo" @change="(e) => onFileUpload(e)" accept="image/png, image/jpeg">
            <select name="state" id="state" v-model="employee.state_id">
                <option :value="state.value" :key="state.value" v-for="state in states">{{ state.name }}</option>
            </select>
            <select name="category" id="category" v-model="employee.category_id">
                <option :value="category.value" :key="category.value" v-for="category in categories">{{ category.name }}</option>
            </select>
            <button type="submit">Zatwierdź</button>
        </form>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  export default{
    name:'BizProfileAddEmployeeVue',
    setup () {
      useMeta({ title: 'Dodaj usługę' });
    },
    data(){
      return{
        states:[],
        categories: [],
        users: 0,
        employeesCount: null,
        usersInPlan: 0,
        employee:{
            firstname: '',
            lastname: '',
            address: '',
            email: '',
            state_id: '',
            phone: '',
            password: '',
            photo: null,
            category_id: '',
            role_id: 4,
        }
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }
      if(this.$store.state.isSubscribed){
          this.getStatesAndCategories()
      }else{
        this.$router.push('/dla-biznesu/profile/user-data')
      }
    },
    methods:{
        getStatesAndCategories(){
            axios.get('/State').then(res => {
                res.data.forEach((item) => {
                    const ob = {
                        name: item.name,
                        value: `${item.id}`
                    }
                    this.states.push(ob)
                })
                this.employee.state_id = this.states[0].value
            })

            axios.get('/Categories').then(res => {
                res.data.forEach((item) => {
                    const ob  = {
                        name: item.name,
                        value: `${item.id}`
                    }
                    this.categories.push(ob)
                })
                this.employee.category_id = this.categories[0].value
            })
            axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
                this.users = res.data.subscription.users
                this.usersInPlan = res.data.subscription.subscription.freeUsers
                }).catch(() => {
                    
                })

            axios.post(`/Users`, {main_id: this.currentUser.data.user_id, page: 1, limit: 1}, authHeader())
                .then(res => {
                    this.employeesCount = res.data.count
                })
        },
        onFileUpload(event){
            this.employee.photo = event.target.files[0]
        },
        addNewEmployee(e){
            e.preventDefault()
            if(this.employeesCount<this.users+this.usersInPlan){
                axios.post(`/Employee`, this.employee, { 
                    headers:{
                        Authorization: `${authHeader().headers.Authorization}`,
                        ['Content-Type']: 'multipart/form-data'
                    }
                }).then(res => {
                    if(String(res.status).charAt(0) == '2'){
                        this.$store.commit('setIsSuccess', 'Pomyślnie dodano pracownika')
                        this.$router.push({path: `/dla-biznesu/profile/employees`})
                    }
                }).catch(() => {
                        this.$store.commit('setIsAlert', 'użytkownik o podanym adresie email już istnieje')
                    })
            }else{
                this.$store.commit('setIsAlert', `Nie możesz dodać już pracowników. Maksymalna ich ilość to: ${this.users + this.usersInPlan}`);
            }
        }
    },
    components:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
    .add-employee-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        &__form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 500px;
            gap: 20px;
            *{
                padding: 8px;
                width: 100%;
                border: 1px solid #B1B1B1;
                border-radius: 8px;
            }
            button[type='submit']{
                background-color: #F2790F;
                border: none;
                cursor: pointer;
            }
        }
    }
  </style>
<template>
    <div class="not-found-cont">
        <img src="@/assets/logo.png" alt="M2Vet">
        <h1>Strona w budowie</h1>
        <h3>Wkrótce pojawi się tutaj strona partnera.</h3>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
export default{
    name:"NotFoundPartner",
    setup () {
        useMeta({title: 'Partner'})
    },
    data(){
        return{

        }
    },
    props:{

    },
    mounted(){

    },
    methods:{

    }  
}
</script>

<style scoped>
.not-found-cont{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found-cont > img{
    width: 150px;
}
</style>
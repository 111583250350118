<template>
    <div class="header-container">
      <h2>Moje wizyty</h2>
      <button :style="{'background-color': areVisitsClosed ? '#919191' : ''}" @click="() => areVisitsClosed = true">zakończone</button>
      <button :style="{'background-color': !areVisitsClosed ? '#919191' : ''}" @click="() => areVisitsClosed = false">nadchodzące</button>
    </div>
    <div v-if="currentUser" class="visits-container">
        <ServiceModal :noScheduleBtn="true"/>
          <div v-if="areVisitsClosed" class="visits-container">
            <div v-for="date in dates.filter(x => new Date(x.date) < new Date())" :key="date.date" class="date-element">
              <button @click="() => date.isOpen = !date.isOpen">{{ date.isOpen ? 'ukryj' : 'pokaż' }} wizyty {{ date.date.split('T')[0] }}</button>
              <div v-if="date.isOpen" class="time-element">
                  <div v-for="item in items.filter(el => el.date == date.date.split('T')[0])" :key="item.time">
                      <span>godzina: {{ item.time }}</span>
                      <span style="font-weight: 900;">{{ pets.filter(pet => pet.id == item.pet_id).length > 0 ? pets.filter(pet => pet.id == item.pet_id)[0].pet_name : null }}</span>
                      <button @click="() => $router.push({path: '/profile/visits', query: {serviceID: item.service_id}})">szczegóły usługi</button>
                      <button class="cancel-visit-btn" @click="() => cancelVisit(item.id)" v-if="!areVisitsClosed">anuluj wizytę</button>
                  </div> 
              </div>
          </div>
        </div>
        <div v-if="!areVisitsClosed" class="visits-container">
            <div v-for="date in dates.filter(x => new Date(x.date) > new Date())" :key="date.date" class="date-element">
              <button @click="() => date.isOpen = !date.isOpen">{{ date.isOpen ? 'ukryj' : 'pokaż' }} wizyty {{ date.date.split('T')[0] }}</button>
              <div v-if="date.isOpen" class="time-element">
                  <div v-for="item in items.filter(el => el.date == date.date.split('T')[0])" :key="item.time">
                      <span>godzina: {{ item.time }}</span>
                      <span style="font-weight: 900;">{{ pets.filter(pet => pet.id == item.pet_id).length > 0 ? pets.filter(pet => pet.id == item.pet_id)[0].pet_name : null }}</span>
                      <button @click="() => $router.push({path: '/profile/visits', query: {serviceID: item.service_id}})">szczegóły usługi</button>
                      <button class="cancel-visit-btn" @click="() => cancelVisit(item.id)">anuluj wizytę</button>
                      <a v-if="item.payment_url && item.payment_price" target="blank" :href="item.payment_url">opłać zaliczkę - {{ item.payment_price }} zł</a>
                      <span v-if="item.payment_url && item.payment_price" style="font-size: 1.6rem;">oczywiście jeśli jeszcze <br> nie opłaciłeś/aś zaliczki</span>
                  </div> 
              </div>
          </div>
        </div>
    </div>
    <div v-else>
      <h4>Ładowanie...</h4>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
import axios from 'axios';
import authHeader from '@/services/auth-header';
  export default{
    name:'VisitsVue',
    setup () {
      useMeta({ title: 'Moje wizyty' });
    },
    data(){
      return{
          dates:[],
          items: [],
          areVisitsClosed: false,
          pets: []
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 3) {
        this.$router.push('/login');
      }
      this.getVisits()
      this.getPets()
    },
    methods:{
        getPets(){
          axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
            this.pets = res.data.pets
          })
        },
        getVisits(){
          axios.get(`/CalendarItemsForUser/${this.currentUser.data.user_id}`).then(res => {
            this.items = res.data
            const datesArr = []
            this.items.forEach(item => {
              // const ob = {
              //   date: item.date,
              //   isOpen: false
              // }
              if(!datesArr.includes(item.date)){
                datesArr.push(`${item.date}T${item.time}`)
              }
            })
            datesArr.forEach(date => {
              const ob = {
                date: date,
                isOpen: false
              }
              this.dates.push(ob)
            })
            this.dates.sort((date1, date2) => new Date(date1.date) - new Date(date2.date))
          }).catch(() => {
            
          })
        },
        cancelVisit(id){
          axios.post('/CalendarItemRemoveUser', {
            calendar_id: id,
            user_id: this.currentUser.data.user_id,
            employee_id: 0
          }, authHeader()).then(res => {
            if(String(res.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'anulowano wizytę')
              this.dates = []
              this.items = []
              this.getVisits()
            }
          }).catch(() => {
            
          })
        }
    },
    components:{
        ServiceModal
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .visits-container{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 20px;
  }
  .date-element{
    background-color: #fff;
    border: 1px solid #b1b1b1;
    padding: 10px 30px;
    border-radius: 8px;
    button{
        background-color: #fff;
        border: 1px solid #F2790F;
        padding: 5px;
        border-radius: 8px;
        cursor: pointer;
    }
}
.time-element{
    margin-top: 10px;
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        button{
            background-color: #77B43F;
            border: none;
        }
        button.cancel-visit-btn{
          background-color: #BB2D3B;
        }
    }
}

.header-container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  button{
    border: none;
    padding: 8px;
    background-color: #d8d8d8;
    border-radius: 8px;
    cursor: pointer;
  }
}
  </style>